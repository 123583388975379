import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { matchPath, useLocation } from "react-router-dom";
import i18n from "@app/i18n";
import { Token } from "@app/types/Token";
import { API_HOST, enterprise } from "@app/utils/enterprise";
import axios from "axios";
import decode from "jwt-decode";

import { useAppStateMachine } from "./useAppStateMachine";

const API_URL = `https://${API_HOST}/video/v2/enterprises/${enterprise}/auth`;

interface AuthProviderProps {
  children: ReactNode;
}

const TokenContext = createContext<string | undefined>(undefined);

export function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState<string | undefined>();
  const { pathname } = useLocation();
  const tokenParam = useMemo(
    () => matchPath<"token", string>("/:token/*", pathname)?.params.token,
    [pathname]
  );
  const { transition } = useAppStateMachine();

  const authenticate = useCallback(async () => {
    if (tokenParam != null) {
      axios
        .post(API_URL, {
          token: tokenParam,
        })
        .then((response) => {
          setToken(response.data);

          try {
            const { language } = decode(response.data) as Token;
            i18n.changeLanguage(language);
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => {
          transition({ type: "ERROR" });
        });
    }
  }, [tokenParam, transition]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>;
}

export function useToken(): string | undefined {
  const token = useContext(TokenContext);

  return token;
}

export function useDecodedToken(): Token | undefined {
  const token = useToken();

  return useMemo(() => {
    if (token === undefined) {
      return undefined;
    }

    return decode(token);
  }, [token]);
}
