import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Variants } from "../../../../components/Button/Button";
import { Browsers, useBrowser } from "../../../../hooks/useBrowser";
import { Systems, useOperatingSystem } from "../../../../hooks/useOperatingSystem";

import { StepProps } from "./Steps";

import styles from "./Steps.module.scss";

interface BrowserHelpProps extends StepProps {
  permissionLoading: boolean;
  onClick: () => void;
}

function Image(
  props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) {
  const { alt, src } = props;

  return (
    <img
      alt={alt}
      src={`https://skedifyvideostorage.blob.core.windows.net/assets/_instructions/${src}`}
    />
  );
}

export function BrowserSpecificHelp(props: BrowserHelpProps) {
  const { stepName } = props;
  const browser = useBrowser();
  const os = useOperatingSystem();
  const { t } = useTranslation("setup");

  function refreshNeeded() {
    // some browsers need a refresh to apply new Settings, but we don't want to start at the top
    window.location.search += `&step=${stepName}`;
  }

  if (os === Systems.OTHER) {
    return null;
  } else if (browser === Browsers.CHROME) {
    // web + android
    if (os === Systems.WEB) {
      return (
        <div className={styles.BrowserSpecificHelp}>
          <h4>{t("permission.browser_specific_help.title") + browser}</h4>
          <ol>
            <li>
              <p>{t("permission.browser_specific_help.chrome.click_icon")}</p>
              <Image alt="chrome button" src="chrome_button.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.select_allow"),
                }}
              />
              <Image alt="chrome modal" src="chrome_modal.png" />
            </li>
            <li>
              <p>{t("permission.browser_specific_help.chrome.done")}</p>
            </li>
          </ol>
          <BrowserSpecificButtons {...props} />
        </div>
      );
    } else if (os === Systems.ANDROID) {
      return (
        <div className={styles.BrowserSpecificHelp}>
          <h4>{t("permission.browser_specific_help.title") + browser}</h4>
          <ol>
            <li>
              <p>{t("permission.browser_specific_help.chrome.android.click_icon")}</p>
              <Image alt="chrome button" src="chrome_android_button.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.android.click_settings"),
                }}
              />
              <Image alt="chrome dropdown" src="chrome_android_dropdown.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.android.click_site_settings"),
                }}
              />
              <Image alt="chrome modal" src="chrome_android_modal_list.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.android.click_all_sites"),
                }}
              />
              <Image alt="chrome modal" src="chrome_android_modal_list_all.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.android.select_site"),
                }}
              />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.chrome.android.enable"),
                }}
              />
              <Image alt="chrome modal" src="chrome_android_enable.png" />
            </li>
            <li>
              <p>{t("permission.browser_specific_help.chrome.android.done")}</p>
            </li>
          </ol>
          <BrowserSpecificButtons {...props} />
        </div>
      );
    }

    return null;
  } else if (browser === Browsers.EDGE) {
    // web
    if (os === Systems.WEB) {
      return (
        <div className={styles.BrowserSpecificHelp}>
          <h4>{t("permission.browser_specific_help.title") + browser}</h4>
          <ol>
            <li>
              <p>{t("permission.browser_specific_help.edge.click_icon")}</p>
              <Image alt="edge button" src="edge_button.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.edge.select_allow"),
                }}
              />
              <Image alt="edge modal" src="edge_modal.png" />
            </li>
            <li>
              <p>{t("permission.browser_specific_help.edge.done")}</p>
            </li>
          </ol>
          <BrowserSpecificButtons {...props} />
        </div>
      );
    } else {
      return null;
    }
  } else if (browser === Browsers.SAFARI) {
    // web + iOS
    if (os === Systems.WEB) {
      return (
        <div className={styles.BrowserSpecificHelp}>
          <h4>{t("permission.browser_specific_help.title") + browser}</h4>
          <ol>
            <li>
              <p>{t("permission.browser_specific_help.safari.ios.button")}</p>
              <Image alt="safari button" src="safari_ios_button.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.ios.dropdown"),
                }}
              />
              <Image alt="safari dropdown" src="safari_ios_dropdown.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.ios.modal"),
                }}
              />
              <Image alt="safari modal" src="safari_ios_modal.png" />
            </li>
            <li>
              <p>{t("permission.browser_specific_help.safari.ios.done")}</p>
            </li>
          </ol>
          <Button onClick={refreshNeeded}>{t(`${stepName}.button_reload`)}</Button>
        </div>
      );
    } else if (os === Systems.IOS) {
      return (
        <div className={styles.BrowserSpecificHelp}>
          <h4>{t("permission.browser_specific_help.title") + browser}</h4>
          <ol>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.click_preferences"),
                }}
              />
              <Image alt="safari dropdown" src="safari_dropdown.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.select_camera"),
                }}
              />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.select_allow"),
                }}
              />
              <Image alt="safari modal" src="safari_modal.png" />
            </li>
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("permission.browser_specific_help.safari.select_microphone"),
                }}
              />
            </li>
            <li>
              <p>{t("permission.browser_specific_help.safari.done")}</p>
            </li>
          </ol>
          <Button onClick={refreshNeeded}>{t(`${stepName}.button_reload`)}</Button>
        </div>
      );
    }

    return null;
  } else if (browser === Browsers.FIREFOX && os === Systems.WEB) {
    // web
    return (
      <div className={styles.BrowserSpecificHelp}>
        <h4>{t("permission.browser_specific_help.title") + browser}</h4>
        <ol>
          <li>
            <p>{t("permission.browser_specific_help.firefox.click_icon")}</p>
            <Image alt="firefox button" src="firefox_buttons.png" />
          </li>
          <li>
            <p>{t("permission.browser_specific_help.firefox.unblock")}</p>
            <Image alt="firefox modal" src="firefox_modal.png" />
          </li>
          <li>
            <p>{t("permission.browser_specific_help.firefox.done")}</p>
          </li>
        </ol>
        <BrowserSpecificButtons {...props} />
      </div>
    );
  } else if (browser === Browsers.OPERA && os === Systems.WEB) {
    // web
    return (
      <div className={styles.BrowserSpecificHelp}>
        <h4>{t("permission.browser_specific_help.title") + browser}</h4>
        <ol>
          <li>
            <p>{t("permission.browser_specific_help.opera.click_icon")}</p>
            <Image alt="opera button" src="opera_button.png" />
          </li>
          <li>
            <p
              dangerouslySetInnerHTML={{
                __html: t("permission.browser_specific_help.opera.select_allow"),
              }}
            />
            <Image alt="opera modal" src="opera_modal.png" />
          </li>
          <li>
            <p>{t("permission.browser_specific_help.opera.done")}</p>
          </li>
        </ol>
        <BrowserSpecificButtons {...props} />
      </div>
    );
  } else if (browser === Browsers.SAMSUNG && os === Systems.ANDROID) {
    // Android
    return (
      <div className={styles.BrowserSpecificHelp}>
        <h4>{t("permission.browser_specific_help.title") + browser}</h4>
        <ol>
          <li>
            <p>{t("permission.browser_specific_help.samsung.click_icon")}</p>
            <Image alt="samsung button" src="samsung_button.png" />
          </li>
          <li>
            <p
              dangerouslySetInnerHTML={{
                __html: t("permission.browser_specific_help.samsung.click_settings"),
              }}
            />
            <Image alt="samsung menu" src="samsung_menu.png" />
          </li>
          <li>
            <p
              dangerouslySetInnerHTML={{
                __html: t("permission.browser_specific_help.samsung.click_sites"),
              }}
            />
            <Image alt="samsung modal" src="samsung_modal_list.png" />
          </li>
          <li>
            <p
              dangerouslySetInnerHTML={{
                __html: t("permission.browser_specific_help.samsung.enable"),
              }}
            />
            <Image alt="samsung modal" src="samsung_modal_enable.png" />
          </li>
          <li>
            <p>{t("permission.browser_specific_help.samsung.done")}</p>
          </li>
        </ol>
        <Button onClick={refreshNeeded}>{t(`${stepName}.button_reload`)}</Button>
      </div>
    );
  } else {
    return null;
  }
}

function BrowserSpecificButtons(props: BrowserHelpProps) {
  const { onClick, permissionLoading, stepName } = props;
  const { t } = useTranslation("setup");

  return (
    <>
      {permissionLoading ? (
        <Button onClick={onClick} variant={Variants.DISABLED}>
          {t(`${stepName}.button.checking`)}
        </Button>
      ) : (
        <Button onClick={onClick}>{t(`${stepName}.button.check_again`)}</Button>
      )}
    </>
  );
}
