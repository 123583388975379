import React from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import {
  backgroundBlurAmountVar,
  edgeBlurAmountVar,
  foregroundThresholdVar,
  videoSegmentationEffectVar,
} from "@app/graphql/cache";
import {
  BACKGROUND_BLUR_AMOUNT,
  EDGE_BLUR_AMOUNT,
  FOREGROUND_THRESHOLD,
} from "@app/hooks/media/constants";
import { mediaController } from "@app/hooks/media/media";
import { useAppointment } from "@app/hooks/useAppointment";
import { Icon, IconTypes } from "@pexip/components";
import { RenderEffects } from "@pexip/media-processor";
import clsx from "clsx";

import styles from "./Settings.module.scss";

const EFFECTS: ReadonlyArray<RenderEffects> = ["none", "blur", "overlay"];

const IS_DEBUG = !!localStorage.getItem("preview.debug");

export function BackgroundsSetting({ showLabel }: { showLabel?: boolean }) {
  const { t } = useTranslation();

  const { backgroundBlurAmount, edgeBlurAmount, foregroundThreshold, onChange } = useBlurSettings();

  return (
    <div className={styles.group}>
      <div className={styles.rightSide}>
        {showLabel && <label>{t("settings:backgrounds.title")}</label>}
        <div className="flex">
          {EFFECTS.map((segmentation) => (
            <SegmentationButton effect={segmentation} key={segmentation} />
          ))}
        </div>
        {IS_DEBUG && (
          <div className="mt-4 space-y-2">
            <div>
              <div className="text-sm font-medium">Foreground threshold: {foregroundThreshold}</div>
              <p className="m-0">Default: {FOREGROUND_THRESHOLD}</p>
              <input
                max="1"
                min="0"
                onChange={(event) => onChange("foregroundThreshold", event.target.value)}
                step="0.05"
                type="range"
                value={foregroundThreshold}
              />
            </div>
            <div>
              <div className="text-sm font-medium">
                Background blur amount: {backgroundBlurAmount}
              </div>
              <p className="m-0">Default: {BACKGROUND_BLUR_AMOUNT}</p>
              <input
                max="20"
                min="0"
                onChange={(event) => onChange("backgroundBlurAmount", event.target.value)}
                step="0.5"
                type="range"
                value={backgroundBlurAmount}
              />
            </div>
            <div>
              <div className="text-sm font-medium">Edge blur amount: {edgeBlurAmount}</div>
              <p className="m-0">Default: {EDGE_BLUR_AMOUNT}</p>
              <input
                max="20"
                min="0"
                onChange={(event) => onChange("edgeBlurAmount", event.target.value)}
                step="0.5"
                type="range"
                value={edgeBlurAmount}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function SegmentationButton({ effect }: { effect: RenderEffects }) {
  const { subject } = useAppointment();
  const { t } = useTranslation();
  if (effect === "none") {
    return (
      <ApplyBackgroundButton effect="none">
        <div>{t(`settings:backgrounds.${effect}`)}</div>
      </ApplyBackgroundButton>
    );
  }
  if (effect === "blur") {
    return (
      <ApplyBackgroundButton effect="blur">
        <Icon source={IconTypes.IconBackgroundBlur} />
        <div>{t(`settings:backgrounds.${effect}`)}</div>
      </ApplyBackgroundButton>
    );
  }
  if (effect === "overlay") {
    return (
      <ApplyBackgroundButton
        effect="overlay"
        style={{ backgroundImage: `url(${subject.branding.backgroundReplacementImage})` }}
      />
    );
  }
}

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface ApplyBackgroundButtonProps extends ButtonProps {
  effect: RenderEffects;
}

function ApplyBackgroundButton({ effect, ...props }: ApplyBackgroundButtonProps) {
  const videoSegmentationEffect = useReactiveVar(videoSegmentationEffectVar);

  return (
    <button
      className={clsx(styles.block, videoSegmentationEffect === effect && styles.isSelected)}
      onClick={() => {
        mediaController.media.applyConstraints({
          video: {
            videoSegmentation: effect,
          },
        });
        localStorage.setItem("videoSegmentationEffect", effect);
        videoSegmentationEffectVar(effect);
      }}
      {...props}
    />
  );
}

function useBlurSettings() {
  const foregroundThreshold = useReactiveVar(foregroundThresholdVar);
  const backgroundBlurAmount = useReactiveVar(backgroundBlurAmountVar);
  const edgeBlurAmount = useReactiveVar(edgeBlurAmountVar);

  function handleChange(
    key: "foregroundThreshold" | "backgroundBlurAmount" | "edgeBlurAmount",
    value: string
  ) {
    const parsedValue = parseFloat(value);

    if (key === "foregroundThreshold") foregroundThresholdVar(parsedValue);
    if (key === "backgroundBlurAmount") backgroundBlurAmountVar(parsedValue);
    if (key === "edgeBlurAmount") edgeBlurAmountVar(parsedValue);

    localStorage.setItem(`preview.${key}`, value);
    mediaController.media.applyConstraints({
      video: {
        [key]: parsedValue,
      },
    });
  }

  return { foregroundThreshold, backgroundBlurAmount, edgeBlurAmount, onChange: handleChange };
}
