import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Icon } from "../../../../components/Icon/Icon";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { AcceptedAppointment } from "../../../../types/Appointment";

import { MicStep, PermissionStep, SoundStep, VideoStep } from "./StepsContent";

import styles from "./Steps.module.scss";

export enum StepNames {
  PERMISSION = "permission",
  VIDEO = "video",
  SOUND = "sound",
  MIC = "mic",
}

export interface StepProps {
  appointment: AcceptedAppointment;
  goToStep: (stepName: StepNames) => void;
  isActive: boolean;
  isDone: boolean;
  isNext: boolean;
  onClickNext: () => void;
  stepName: StepNames;
}

export function StepWrapper(props: StepProps) {
  const { goToStep, isActive, isDone, isNext, stepName } = props;
  const { t } = useTranslation("setup");
  const { isMobile } = useMediaQuery();

  const renderContent = () => {
    switch (stepName) {
      case StepNames.PERMISSION:
        return <PermissionStep {...props} />;
      case StepNames.VIDEO:
        return <VideoStep {...props} />;
      case StepNames.MIC:
        return <MicStep {...props} />;
      case StepNames.SOUND:
        return <SoundStep {...props} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={clsx(
        styles.Step,
        isActive && styles.Active,
        isDone && styles.Done,
        isNext && styles.Next
      )}
      id={stepName}
    >
      <div className={styles.Bullet}>
        {isDone && (
          <span className={styles.Icon}>
            <Icon icon="check" size={isActive && !isMobile ? "lg" : "xs"} />
          </span>
        )}
      </div>
      <div className={styles.Right}>
        <h5
          className={styles.Header}
          onClick={isDone || isNext ? () => goToStep(stepName) : undefined}
        >
          {t(`${stepName}.title`)}
        </h5>

        {isActive && (
          <div className={styles.ContentFade}>
            <div className={styles.Content}>{renderContent()}</div>
          </div>
        )}
      </div>
    </div>
  );
}
