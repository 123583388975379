import React, { ReactElement } from "react";
import ReactModal, { Props as ModalProps } from "react-modal";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Button, Variants } from "../Button/Button";
import { Icon } from "../Icon/Icon";

import styles from "./Modal.module.scss";

ReactModal.setAppElement("#root");

const overlayStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .35)",
    zIndex: 1000001,
  },
};

export function Modal(
  props: ModalProps & {
    children: ReactElement | ReactElement[];
    hideCloseButton?: boolean;
  }
) {
  const { children, hideCloseButton, ...modalProps } = props;
  const { isMobile } = useMediaQuery();

  function handleClose(evt: React.MouseEvent<HTMLElement>) {
    if (modalProps.onRequestClose !== undefined) {
      modalProps.onRequestClose(evt);
    }
  }

  return (
    <ReactModal
      {...modalProps}
      className={styles.Modal}
      closeTimeoutMS={100}
      shouldCloseOnOverlayClick={!isMobile && modalProps.shouldCloseOnOverlayClick}
      style={overlayStyles}
    >
      <div data-ignore-outside-click="true">
        {!hideCloseButton && (
          <Button onClick={handleClose} variant={Variants.FLAT}>
            <Icon icon="times" />
          </Button>
        )}
        {children}
      </div>
    </ReactModal>
  );
}
