import React, { useEffect, useMemo, useRef, useState } from "react";
import { differenceInSeconds, parseISO } from "date-fns";

import { formatDuration } from "../../utils/formatDuration";

import styles from "./Recordings.module.scss";

interface Props {
  start?: string;
}

export function RecordingDuration(props: Props) {
  const { start } = props;
  const startTime = useMemo(
    () => differenceInSeconds(new Date(), start !== undefined ? parseISO(start) : new Date()),
    [start]
  );
  const [timer, setTimer] = useState(startTime);
  const increment = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => {
      if (increment.current != null) {
        clearInterval(increment.current);
      }
    };
  }, [increment]);

  return (
    <div className={styles.Duration}>
      <div className={styles.dot} />
      <div>{formatDuration(timer)}</div>
    </div>
  );
}
