interface Identity {
  id: string;
}

export function removeById<T extends Identity>(
  array: Array<T> | undefined,
  removeObject: Partial<T>
): Array<T> {
  if (array == null) {
    return [];
  }

  return array.filter((element) => element.id !== removeObject.id);
}

export function upsertObject<T extends Identity>(
  array: Array<T> | undefined,
  object: Partial<T>,
  insertAtEnd?: boolean
): Array<T> {
  if (array == null) {
    return [];
  }

  const idx = array.findIndex((element) => element.id === object.id);

  if (idx === -1) {
    if (insertAtEnd === true) {
      return [...array, object as T];
    }

    return [object as T, ...array];
  }

  return [...array.slice(0, idx), { ...array[idx], ...object }, ...array.slice(idx + 1)];
}
