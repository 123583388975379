import { ApolloClient, gql, HttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { API_HOST } from "@app/utils/enterprise";

import { cache } from "./cache";

const typeDefs = gql`
  extend type TimelineEvent {
    isSaving: Boolean
  }
`;

const wsLink = new WebSocketLink({
  uri: `wss://${API_HOST}/video/subscriptions`,
  options: {
    reconnect: true,
  },
});
const httpLink = new HttpLink({
  fetch: (_, options) => {
    // @ts-ignore
    const { operationName } = JSON.parse(options?.body ?? "{}");

    return fetch(`https://${API_HOST}/video/graphql?q=${operationName}`, options);
  },
});

export function getApolloClient(token: string | null | undefined) {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    assumeImmutableResults: true,
    cache,
    link,
    typeDefs,
  });
}
