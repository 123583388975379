import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useLocalMedia } from "@app/hooks/media/media";
import { useSwitchDisconnectedDevice } from "@app/hooks/media/useDevices";
import { useGUM } from "@app/hooks/media/useGum";

import { Banner } from "../../components/Banner/Banner";
import { Button, Variants } from "../../components/Button/Button";
import { DateTime } from "../../components/DateTime/DateTime";
import { Icon } from "../../components/Icon/Icon";
import { PhotoContainer } from "../../components/PhotoContainer/PhotoContainer";
import { Spacer } from "../../components/Spacer/Spacer";
import { UserVideo } from "../../components/Video/UserVideo";
import { CONNECT_PARTICIPANT } from "../../graphql/mutations/participant";
import { useAppStateMachine } from "../../hooks/useAppStateMachine";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useModules } from "../../hooks/useModules";
import { useDecodedToken } from "../../hooks/useToken";
import { AppointmentStatus, ModuleName } from "../../types/api";
import { AcceptedAppointment } from "../../types/Appointment";

import { AppointmentOptions } from "./components/AppointmentOptions/AppointmentOptions";
import { Contact } from "./components/Contact/Contact";
import { DialIn } from "./components/DialIn/DialIn";
import { Instructions } from "./components/Instructions/Instructions";

import styles from "./WaitingRoom.module.scss";

interface Props {
  appointment: AcceptedAppointment;
}

export function WaitingRoom({ appointment }: Props) {
  const { t } = useTranslation();
  const { transition } = useAppStateMachine();
  const [connectParticipant] = useMutation(CONNECT_PARTICIPANT);
  const { isDesktop } = useMediaQuery();
  const [isAvailable, setIsAvailable] = useState(false);
  const token = useDecodedToken();
  const { isModuleEnabled: isRecordingsEnabled, moduleSettings } = useModules(
    ModuleName.RECORDINGS
  );

  const localMedia = useLocalMedia();

  useGUM();
  useSwitchDisconnectedDevice();

  const availableAt = appointment.availableAt;

  const collocutor = appointment.participants.find(
    (participant) => participant.id !== token?.participantId
  );
  const currentUser = appointment.participants.find(
    (participant) => participant.id === token?.participantId
  );

  const title = `${t("hello")}, <br/><strong>${currentUser?.fullName}</strong>!`;

  const { message, waitingForOther } = token?.isHost
    ? {
        waitingForOther: appointment.status === AppointmentStatus.HOST_READY,
        message: "waiting_room:waiting_for_customer",
      }
    : {
        waitingForOther: appointment.status === AppointmentStatus.CUSTOMER_READY,
        message: "waiting_room:waiting_for_host",
      };

  const onParticipantConnect = useCallback(() => {
    connectParticipant({
      update: (_, { data }) => {
        if (data.connectParticipant.success) {
          transition({
            type: token?.isHost ? AppointmentStatus.HOST_READY : AppointmentStatus.CUSTOMER_READY,
            appointmentStatus: appointment.status,
          });
        }
      },
    });
  }, [appointment.status, connectParticipant, token, transition]);

  useEffect(() => {
    if (!isAvailable && availableAt != null) {
      const delay = new Date(availableAt).getTime() - Date.now();

      if (delay > 0) {
        const rerender = setTimeout(() => {
          setIsAvailable(true);
        }, delay);

        return () => {
          clearTimeout(rerender);
        };
      }

      return setIsAvailable(true);
    }
  }, [isAvailable, availableAt]);

  return (
    <PhotoContainer branding={appointment.subject.branding} titleLeft={title}>
      <div className={styles.WaitingRoom}>
        <h2>
          <DateTime appointment={appointment as AcceptedAppointment} />
          <br />
          {t("waiting_room:intro")}
        </h2>
        {collocutor !== undefined && (
          <Spacer>
            <Contact contact={collocutor} subject={appointment.subject} />
          </Spacer>
        )}
        <Spacer>
          <div className={styles.video}>
            <UserVideo media={localMedia} hasControls />
          </div>
        </Spacer>

        <Spacer>
          {isAvailable ? (
            <div className={styles.buttons}>
              <Button
                onClick={onParticipantConnect}
                variant={waitingForOther ? Variants.DISABLED : Variants.DEFAULT}
              >
                {waitingForOther ? t(message) : t("waiting_room:start")}
              </Button>
              <span>{t("waiting_room:join_options")}</span>
              <DialIn
                dialInCode={token?.aliases.find((a) => a.type === "DIAL_IN_CODE")?.value}
                dialInNumber={appointment.dialInNumber}
                dialInPin={token?.pin}
              />
            </div>
          ) : (
            <Banner type="warning">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("waiting_room:vmr_not_available"),
                }}
              />
            </Banner>
          )}
          <div>
            {isRecordingsEnabled && (
              <p>
                <Icon icon="info-circle" />
                &nbsp;
                {moduleSettings.RECORDING_BY_DEFAULT_ENABLED
                  ? t("waiting_room:recordings.automatic")
                  : t("waiting_room:recordings.manual")}
              </p>
            )}
          </div>

          <>{currentUser !== undefined && <AppointmentOptions currentUser={currentUser} />}</>
        </Spacer>

        {!token?.isHost && isDesktop && (
          <div className={styles.right}>
            <Instructions instructions={appointment.subject.instructions} />
          </div>
        )}
      </div>
    </PhotoContainer>
  );
}
