import { ReactElement } from "react";
import { Environment, environment } from "@app/utils/enterprise";
import { useActor } from "@xstate/react";
import { createMachine, interpret } from "xstate";

import { AppointmentStatus } from "../types/api";

export enum AppStates {
  LOADING = "LOADING",
  ERROR = "ERROR",
  PENDING = "PENDING",
  WAITING_ROOM = "WAITING_ROOM",
  MEETING_ROOM = "MEETING_ROOM",
  END_SCREEN = "END_SCREEN",
  CANCELLED = "CANCELLED",
  SETUP = "SETUP",
}

interface AppStateSchema {
  states: {
    [AppStates.LOADING]: {};
    [AppStates.ERROR]: {};
    [AppStates.PENDING]: {};
    [AppStates.WAITING_ROOM]: {};
    [AppStates.MEETING_ROOM]: {};
    [AppStates.END_SCREEN]: {};
    [AppStates.CANCELLED]: {};
    [AppStates.SETUP]: {};
  };
}

type AppEvent =
  | { type: "ERROR" }
  | { type: "SETUP" }
  | { type: "SETUP_DONE" }
  | { type: AppointmentStatus.PENDING }
  | { type: AppointmentStatus.SCHEDULED }
  | {
      type: AppointmentStatus.HOST_READY;
      appointmentStatus: AppointmentStatus;
    }
  | {
      type: AppointmentStatus.CUSTOMER_READY;
      appointmentStatus: AppointmentStatus;
    }
  | { type: AppointmentStatus.STARTED }
  | { type: AppointmentStatus.ENDED }
  | { type: AppointmentStatus.CANCELLED };

const appStateMachine = createMachine<AppStateSchema, AppEvent>(
  {
    key: "app",
    initial: AppStates.LOADING,
    states: {
      LOADING: {
        on: {
          ENDED: AppStates.END_SCREEN,
          ERROR: AppStates.ERROR,
          HOST_READY: AppStates.WAITING_ROOM,
          CUSTOMER_READY: AppStates.WAITING_ROOM,
          STARTED: AppStates.MEETING_ROOM,
          SCHEDULED: AppStates.WAITING_ROOM,
          CANCELLED: AppStates.CANCELLED,
          PENDING: AppStates.PENDING,
          SETUP: AppStates.SETUP,
        },
      },
      PENDING: {
        on: {
          SCHEDULED: AppStates.WAITING_ROOM,
          CANCELLED: AppStates.CANCELLED,
        },
      },
      WAITING_ROOM: {
        on: {
          CUSTOMER_READY: {
            target: AppStates.MEETING_ROOM,
            cond: "allParticipantsReady",
          },
          HOST_READY: {
            target: AppStates.MEETING_ROOM,
            cond: "allParticipantsReady",
          },
          STARTED: AppStates.MEETING_ROOM,
          CANCELLED: AppStates.CANCELLED,
          PENDING: AppStates.PENDING,
          SETUP: AppStates.SETUP,
        },
      },
      MEETING_ROOM: {
        on: {
          ENDED: AppStates.END_SCREEN,
          SETUP: AppStates.SETUP,
        },
      },
      SETUP: {
        on: {
          // TODO: Or MEETING_ROOM
          SETUP_DONE: AppStates.WAITING_ROOM,
        },
      },
      END_SCREEN: {
        type: "final",
      },
      ERROR: {
        type: "final",
      },
      CANCELLED: {
        type: "final",
      },
    },
  },
  {
    guards: {
      allParticipantsReady: (_, event) =>
        (event.type === AppointmentStatus.HOST_READY &&
          event.appointmentStatus === AppointmentStatus.CUSTOMER_READY) ||
        (event.type === AppointmentStatus.CUSTOMER_READY &&
          event.appointmentStatus === AppointmentStatus.HOST_READY),
    },
  }
);

const appMachineService = interpret(appStateMachine, {
  devTools: environment === Environment.DEVELOPMENT,
}).start();

export function useAppStateMachine() {
  const [currentState, transition] = useActor(appMachineService);

  return {
    currentState,
    transition,
  };
}

export function Match({ children, state }: { children: ReactElement; state: AppStates }) {
  const { currentState } = useAppStateMachine();

  if (currentState.matches(state)) {
    return children;
  }

  return null;
}
