import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { presentationStatusVar } from "@app/graphql/cache";
import * as Sentry from "@sentry/browser";

import { ToastBody } from "../components/ToastContainer/components/ToastBody/ToastBody";
import { AppointmentStatus } from "../types/api";
import { AcceptedAppointment } from "../types/Appointment";
import { fullName } from "../utils/user";

import { useLocalMedia } from "./media/media";
import { useConfirm } from "./useConfirm";
import { useDecodedToken } from "./useToken";
import { useWindowUnloadEffect } from "./useWindowUnloadEffect";

export const pexRTC = new window.PexRTC();
export enum PexipStatus {
  INITIAL = "initial",
  REQUESTING = "requesting",
  READY = "ready",
  ERROR = "error",
}

export function usePexip(appointment: AcceptedAppointment) {
  const [status, setStatus] = useState<PexipStatus>(PexipStatus.INITIAL);
  const [stream, setStream] = useState<MediaStream>();
  const [presentationStream, setPresentationStream] = useState<MediaStream>();
  const [presentationSelfStream, setPresentationSelfStream] = useState<MediaStream>();
  const [participants, setParticipants] = useState<Roster[]>([]);
  const currentUser = useDecodedToken();
  const confirm = useConfirm();
  const { t } = useTranslation(["meeting_room", "errors"]);
  const localMedia = useLocalMedia();

  function setupComplete(_: any, pinStatus: PinStatus) {
    if (pinStatus === "none") {
      return pexRTC?.connect();
    }

    if (currentUser?.pin === undefined) {
      return;
    }

    return pexRTC?.connect(currentUser.pin);
  }

  function connectComplete(stream: MediaStream) {
    setStatus(PexipStatus.READY);
    setStream(stream);
  }

  function setupPresentation(setting: boolean) {
    if (setting) {
      return pexRTC?.getPresentation();
    }
    pexRTC?.stopPresentation();
  }

  function handleError(error: string) {
    setStatus(PexipStatus.ERROR);
    console.error(error);

    const errorCode = error.includes("Could not get access to camera/microphone")
      ? "gum_failed"
      : "generic_technical";

    toast.error(
      <ToastBody
        icon="video"
        message={t(`errors:${errorCode}.message`)}
        title={t(`errors:${errorCode}.title`)}
        variant="error"
      />,
      {
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
      }
    );
    Sentry.captureException(new Error(error));
  }

  function notifyDisconnect(reason: string) {
    confirm(t("disconnected.message"), () => window.location.reload(), {
      confirmLabel: t("disconnected.reconnect"),
      isCancelDisabled: true,
    });
  }

  // TODO: move this to useEffect so this doesn't get re-assigned every re-render
  if (pexRTC) {
    pexRTC.onError = handleError;
    pexRTC.onConnect = connectComplete;
    pexRTC.onDisconnect = notifyDisconnect;
    pexRTC.onRosterList = setParticipants;
    pexRTC.onPresentation = setupPresentation;
    pexRTC.onPresentationConnected = (stream) => {
      setPresentationStream(stream);
    };
    pexRTC.onPresentationDisconnected = () => {
      setPresentationStream(undefined);
    };
    pexRTC.onRosterList = setParticipants;
    pexRTC.onScreenshareConnected = (stream) => {
      setPresentationSelfStream(stream);
    };
    pexRTC.onScreenshareStopped = (reason) => {
      setPresentationSelfStream(undefined);
    };
    pexRTC.onSetup = setupComplete;
    pexRTC.onLog = console.debug;
  }

  const {
    VITE_TURN_SERVER_CREDENTIAL: credential,
    VITE_TURN_SERVER_URLS: urls,
    VITE_TURN_SERVER_USERNAME: username,
  } = import.meta.env;

  // TODO: move this to useEffect so this doesn't get re-assigned every re-render
  if (pexRTC && urls && credential && username) {
    pexRTC.turn_server = {
      urls,
      username,
      credential,
    };
  }

  useEffect(() => {
    if (
      appointment.status === AppointmentStatus.STARTED &&
      currentUser !== undefined &&
      status === "initial"
    ) {
      const { pexipNode, pexipVMR } = appointment;
      const name = fullName(currentUser);
      const conference = currentUser.aliases.find((a) => a.type === "SLUG")?.value ?? pexipVMR;

      if (localMedia?.stream) {
        setStatus(PexipStatus.REQUESTING);
        pexRTC.user_media_stream = localMedia.stream;
        pexRTC.makeCall(pexipNode, conference, name);
      }
    }
    // Note: Explicitly set this on appointment.status because currentUser
    // and pexipNode & pexipVMR will not change once appointment is loaded
    // eslint-disable-next-line
  }, [appointment?.status, localMedia.stream, status]);

  useEffect(() => {
    if (localMedia?.stream) {
      pexRTC.user_media_stream = localMedia.stream;
      // Do we need this?
      pexRTC.renegotiate(false);
    }
  }, [localMedia]);

  useEffect(() => {
    if (presentationStream) {
      presentationStatusVar("presenting:other");
    } else if (presentationSelfStream) {
      presentationStatusVar("presenting:self");
    } else {
      presentationStatusVar("none");
    }
  }, [presentationStream, presentationSelfStream]);

  useWindowUnloadEffect(() => pexRTC.disconnect());

  return {
    participants,
    presentationStream,
    presentationSelfStream,
    stream,
  };
}
