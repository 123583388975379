import { InMemoryCache, makeVar } from "@apollo/client";
import { BANDWIDTHS } from "@app/hooks/media/constants";
import { RenderEffects } from "@pexip/media-processor";

export const cache = new InMemoryCache({
  typePolicies: {
    TimelineEvent: {
      fields: {
        isSaving: (value) => {
          return value ?? false;
        },
      },
    },
  },
});

export const isPictureInPictureStoppedVar = makeVar<boolean>(true);
export const presentationStatusVar = makeVar<"none" | "presenting:self" | "presenting:other">(
  "none"
);
export const isSettingsShownVar = makeVar<boolean>(false);
export const isSideNavMobileShownVar = makeVar<boolean>(false);
export const isMicrophoneMutedVar = makeVar<boolean>(
  localStorage.getItem("isMicrophoneMuted") === "true"
);
export const isVolumeMutedVar = makeVar<boolean>(localStorage.getItem("isVolumeMuted") === "true");
export const isVideoMutedVar = makeVar<boolean>(localStorage.getItem("isVideoMuted") === "true");
export const audioInputDeviceIdVar = makeVar<string | null>(
  localStorage.getItem("audioInputDeviceId")
);
export const audioOutputDeviceIdVar = makeVar<string | null>(
  localStorage.getItem("audioOutputDeviceId")
);
export const videoInputDeviceIdVar = makeVar<string | null>(
  localStorage.getItem("videoInputDeviceId")
);
const [low] = BANDWIDTHS;
export const bandwidthVar = makeVar<string>(localStorage.getItem("bandwidth") || low);

export const videoSegmentationEffectVar = makeVar<RenderEffects | null>(
  localStorage.getItem("videoSegmentationEffect") === "blur"
    ? "blur"
    : localStorage.getItem("videoSegmentationEffect") === "overlay"
    ? "overlay"
    : "none"
);

export const foregroundThresholdVar = makeVar<number | null>(
  localStorage.getItem("preview.foregroundThreshold")
    ? parseFloat(localStorage.getItem("preview.foregroundThreshold"))
    : null
);
export const backgroundBlurAmountVar = makeVar<number | null>(
  localStorage.getItem("preview.backgroundBlurAmount")
    ? parseFloat(localStorage.getItem("preview.backgroundBlurAmount"))
    : null
);
export const edgeBlurAmountVar = makeVar<number | null>(
  localStorage.getItem("preview.edgeBlurAmount")
    ? parseFloat(localStorage.getItem("preview.edgeBlurAmount"))
    : null
);
