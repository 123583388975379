import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useReactiveVar } from "@apollo/client";
import { mediaController, toggleFacingMode } from "@app/hooks/media/media";
import { pexRTC } from "@app/hooks/usePexip";
import { useDecodedToken } from "@app/hooks/useToken";
import isMobileDevice from "ismobilejs";

import {
  isMicrophoneMutedVar,
  isPictureInPictureStoppedVar,
  isSettingsShownVar,
  isSideNavMobileShownVar,
  isVideoMutedVar,
  isVolumeMutedVar,
  presentationStatusVar,
} from "../../graphql/cache";
import { END_APPOINTMENT } from "../../graphql/mutations/appointment";
import { DISCONNECT_PARTICIPANT } from "../../graphql/mutations/participant";
import { useAppStateMachine } from "../../hooks/useAppStateMachine";
import { useConfirm } from "../../hooks/useConfirm";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { AppointmentStatus } from "../../types/api";
import { Controls as ControlsType, ControlVariants } from "../../types/Control";

export function useControls(isMain?: boolean) {
  const { t } = useTranslation("controls");
  const token = useDecodedToken();
  const { transition } = useAppStateMachine();
  const confirm = useConfirm();
  const { isMobile } = useMediaQuery();

  const [disconnectParticipant] = useMutation(DISCONNECT_PARTICIPANT);
  const [endAppointment] = useMutation(END_APPOINTMENT);

  const isMicrophoneMuted = useReactiveVar(isMicrophoneMutedVar);
  const isPictureInPictureStopped = useReactiveVar(isPictureInPictureStoppedVar);
  const presentationStatus = useReactiveVar(presentationStatusVar);
  const isSettingsShown = useReactiveVar(isSettingsShownVar);
  const isSideNavMobileShown = useReactiveVar(isSideNavMobileShownVar);
  const isVideoMuted = useReactiveVar(isVideoMutedVar);
  const isVolumeMuted = useReactiveVar(isVolumeMutedVar);

  const controls: Pick<ControlsType, "microphone" | "navigation" | "settings" | "video"> = useMemo(
    () => ({
      ...(isMain &&
        isMobile && {
          navigation: {
            value: isSideNavMobileShown,
            onToggle: isSideNavMobileShownVar,
          },
        }),
      ...(isMain &&
        !isMobile && {
          audio: {
            value: isVolumeMuted,
            variant: isVolumeMuted ? ControlVariants.OFF : ControlVariants.DEFAULT,
            onToggle: isVolumeMutedVar,
          },
        }),
      microphone: {
        value: isMicrophoneMuted,
        variant: isMicrophoneMuted ? ControlVariants.OFF : ControlVariants.DEFAULT,
        onToggle: (isMuted: boolean) => {
          isMicrophoneMutedVar(isMuted);
          localStorage.setItem("isMicrophoneMuted", isMuted.toString());

          pexRTC.muteAudio(isMuted);

          mediaController.media.muteAudio(isMuted);
        },
      },
      video: {
        value: isVideoMuted,
        variant: isVideoMuted ? ControlVariants.OFF : ControlVariants.DEFAULT,
        onToggle: (isMuted: boolean) => {
          isVideoMutedVar(isMuted);
          localStorage.setItem("isVideoMuted", isMuted.toString());
          pexRTC.muteVideo(isMuted);

          mediaController.media.muteVideo(isMuted);
        },
      },
      ...(isMobile &&
        isMobileDevice(navigator.userAgent).any && {
          userFacing: {
            // Only has a single control, so we don't have to give it the actual value
            value: true,
            onToggle: toggleFacingMode,
          },
        }),
      ...(isMain &&
        !isMobile && {
          presentation: {
            variant:
              presentationStatus === "none" || presentationStatus === "presenting:other"
                ? ControlVariants.DEFAULT
                : ControlVariants.ON,
            value:
              presentationStatus === "none" || presentationStatus === "presenting:other"
                ? true
                : false,
            onToggle: (isStopped: boolean) => {
              pexRTC.present(isStopped ? null : "screen");
            },
          },
        }),
      ...(isMain &&
        "pictureInPictureEnabled" in document &&
        !isMobile && {
          pictureInPicture: {
            variant: isPictureInPictureStopped ? ControlVariants.DEFAULT : ControlVariants.ON,
            value: isPictureInPictureStopped,
            onToggle: isPictureInPictureStoppedVar,
          },
        }),
      ...(!isMain && {
        settings: {
          value: isSettingsShown,
          onToggle: isSettingsShownVar,
        },
      }),
      ...(isMain && {
        disconnect: {
          value: false,
          variant: ControlVariants.DANGER,
          onToggle: () => {
            if (token?.isHost) {
              return confirm(
                t("confirm_end_meeting_message"),
                () => {
                  disconnectParticipant();
                  endAppointment();
                  pexRTC.disconnect();
                },
                { confirmLabel: t("confirm_end_meeting_btn") }
              );
            }

            return confirm(
              t("confirm_disconnect_message"),
              () => {
                disconnectParticipant();
                pexRTC.disconnect();
                transition({ type: AppointmentStatus.ENDED });
              },
              { confirmLabel: t("confirm_disconnect_btn") }
            );
          },
        },
      }),
    }),
    [
      confirm,
      disconnectParticipant,
      endAppointment,
      isMain,
      isMicrophoneMuted,
      isMobile,
      isPictureInPictureStopped,
      presentationStatus,
      isSettingsShown,
      isSideNavMobileShown,
      isVideoMuted,
      isVolumeMuted,
      t,
      token?.isHost,
      transition,
    ]
  );

  return controls;
}
