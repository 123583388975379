import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Variants } from "@app/components/Button/Button";
import { ToastBody } from "@app/components/ToastContainer/components/ToastBody/ToastBody";
import { useToken } from "@app/hooks/useToken";
import { API_HOST, enterprise } from "@app/utils/enterprise";
import * as Sentry from "@sentry/browser";
import axios from "axios";

interface Props {
  label?: string;
  isFullWidth?: boolean;
  variant?: Variants;
}

const DOWNLOAD_URL = `https://${API_HOST}/video/v2/enterprises/${enterprise}/download`;

export function DownloadButton(props: Props) {
  const { isFullWidth, label, variant } = props;
  const { t } = useTranslation("files");
  const token = useToken();
  const [isDownloading, setIsDownloading] = useState(false);

  async function downloadZIP() {
    try {
      setIsDownloading(true);
      const resultStream = await axios.get(DOWNLOAD_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      });
      const blob = new Blob([resultStream.data], {
        type: "application/zip",
      });

      const disposition = resultStream.request.getResponseHeader("Content-Disposition");
      const fileName = disposition?.split("filename=").pop();

      const hyperLink = document.createElement("a");

      hyperLink.href = window.URL.createObjectURL(blob);
      hyperLink.target = "_blank";
      hyperLink.rel = "noopener noreferrer";
      hyperLink.download = fileName;

      hyperLink.click();
      window.URL.revokeObjectURL(DOWNLOAD_URL);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(
        <ToastBody
          icon="arrow-down"
          message={t("download_all.error.message")}
          title={t("download_all.error.title")}
          variant="error"
        />
      );
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <Button
      isFullWidth={isFullWidth}
      isLoading={isDownloading}
      loadingMessage={t("generating_archive")}
      onClick={() => downloadZIP()}
      variant={variant ?? Variants.FLAT}
    >
      {label ?? t("download_all.button")}
    </Button>
  );
}
