import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useReactiveVar } from "@apollo/client";
import { ToastBody } from "@app/components/ToastContainer/components/ToastBody/ToastBody";
import { isMicrophoneMutedVar } from "@app/graphql/cache";

import { signals } from "./media";

export function useSpeakingWhileMuted() {
  const isMicrophoneMuted = useReactiveVar(isMicrophoneMutedVar);
  const [isSpeakingWhileMuted, setIsSpeakingWhileMuted] = useState(false);
  const [isFeatureEnabled] = useState(localStorage.getItem("preview.vad") === "true");

  useEffect(() => {
    if (!isFeatureEnabled) return;
    if (!isMicrophoneMuted) {
      setIsSpeakingWhileMuted(false);
    }

    return signals.onVAD.add(() => {
      if (isMicrophoneMuted) {
        toast.error(
          <ToastBody
            icon="video"
            message="Did you forget to unmute your microphone?"
            title="You are speaking, but you are muted"
            variant="info"
          />,
          {
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          }
        );

        return setIsSpeakingWhileMuted(true);
      }
    });
  }, [isFeatureEnabled, isMicrophoneMuted]);

  return isSpeakingWhileMuted;
}
