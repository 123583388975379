export function fullName(
  user: Partial<{ firstName: string | undefined; lastName: string | undefined } | undefined> = {}
): string {
  const { firstName, lastName } = user;

  if (firstName == null && lastName == null) {
    return "Anonymous";
  }

  return [firstName, lastName].filter(Boolean).join(" ");
}
