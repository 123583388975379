import { useQuery } from "@apollo/client";

import { MODULE_SETTINGS } from "../graphql/queries/module";
import { ModuleName, ModuleSettingType } from "../types/api";
import { ModuleSettings } from "../types/Module";

import { useDecodedToken } from "./useToken";

function castModuleSettingValueToType(
  moduleSettingValue: string,
  moduleSettingType: ModuleSettingType
): boolean | number | string {
  switch (moduleSettingType) {
    case ModuleSettingType.Boolean:
      return moduleSettingValue === "true";
    case ModuleSettingType.Number:
      return parseInt(moduleSettingValue, 10);
    default:
      return moduleSettingValue;
  }
}

function mapModuleSettingsToObject(moduleSettings?: ModuleSettings[]) {
  return (moduleSettings ?? []).reduce((obj, setting) => {
    const { key, type, value } = setting;

    return value == null ? obj : { ...obj, [key]: castModuleSettingValueToType(value, type) };
  }, {} as { [k: string]: boolean | number | string });
}

export function useModules(moduleName: ModuleName) {
  const { allowedModules } = useDecodedToken() ?? {};
  const isModuleEnabled = allowedModules?.includes(moduleName) ?? false;

  const { data } = useQuery<{ moduleSettings: ModuleSettings[] }>(MODULE_SETTINGS, {
    variables: { moduleName },
    skip: !isModuleEnabled,
  });

  const moduleSettings = mapModuleSettingsToObject(data?.moduleSettings);

  return { isModuleEnabled, moduleSettings };
}
