import { useCallback, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";

import { isPictureInPictureStoppedVar } from "../graphql/cache";

export function usePictureInPicture(videoRef: React.RefObject<HTMLVideoElement>) {
  const isPictureInPictureStopped = useReactiveVar(isPictureInPictureStoppedVar);
  const isPictureInPicture = !isPictureInPictureStopped;

  const handleEnterPictureInPicture = useCallback(() => {
    isPictureInPictureStoppedVar(false);
  }, []);

  const handleLeavePictureInPicture = useCallback(() => {
    isPictureInPictureStoppedVar(true);
  }, []);

  const requestPictureInPicture = useCallback(
    async (videoElement: HTMLVideoElement) => {
      try {
        // @ts-ignore
        await videoElement.requestPictureInPicture();
        videoElement.addEventListener("leavepictureinpicture", handleLeavePictureInPicture);
      } catch (error) {
        handleLeavePictureInPicture();
      }
    },
    [handleLeavePictureInPicture]
  );

  const exitPictureInPicture = useCallback(() => {
    try {
      // @ts-ignore
      if (document.pictureInPictureElement != null) {
        // @ts-ignore
        document.exitPictureInPicture();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (videoRef.current == null) {
      return;
    }

    if (isPictureInPicture) {
      requestPictureInPicture(videoRef.current);
    } else {
      exitPictureInPicture();
      videoRef.current.addEventListener("enterpictureinpicture", handleEnterPictureInPicture);
    }
  }, [
    exitPictureInPicture,
    handleEnterPictureInPicture,
    isPictureInPicture,
    requestPictureInPicture,
    videoRef,
  ]);
}
