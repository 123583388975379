import warning from "tiny-warning";

export enum Environment {
  DEVELOPMENT = "development",
  QA = "qa",
  STAGING = "staging",
  DEMO = "demo",
  PRODUCTION = "production",
}

const HOSTNAME_ENVIRONMENT_MAP: Record<string, Environment> = {
  development: Environment.DEVELOPMENT,
  "qa.development": Environment.QA,
  staging: Environment.STAGING,
  demo: Environment.DEMO,
  production: Environment.PRODUCTION,
  k8s: Environment.DEVELOPMENT,
  skedify: Environment.PRODUCTION,
};

const API_HOST_MAP: Record<Environment, string> = {
  [Environment.DEVELOPMENT]: "api.development.skedify.io",
  [Environment.QA]: "api.qa.development.skedify.io",
  [Environment.STAGING]: "api.staging.skedify.io",
  [Environment.DEMO]: "api.demo.skedify.me",
  [Environment.PRODUCTION]: "api.skedify.io",
};

const APP_HOST_MAP: Record<Environment, string> = {
  [Environment.DEVELOPMENT]: "<enterprise>.development.skedify.io",
  [Environment.QA]: "<enterprise>.development.skedify.io",
  [Environment.STAGING]: "<enterprise>.staging.skedify.me",
  [Environment.DEMO]: "<enterprise>.demo.skedify.me",
  [Environment.PRODUCTION]: "<enterprise>.skedify.me",
};

export function getEnvironmentSettings() {
  const [hostnameEnterprise, hostnameEnvironment] = window.location.hostname
    .replace(".skedify.video", "")
    .split(/\.(.*)/s);

  const environment =
    window.location.hostname === "localhost"
      ? "development"
      : HOSTNAME_ENVIRONMENT_MAP[hostnameEnvironment] ?? Environment.PRODUCTION;
  const enterprise = window.location.hostname === "localhost" ? "frontend" : hostnameEnterprise;
  const API_HOST = API_HOST_MAP[environment];
  const APP_HOST = APP_HOST_MAP[environment].replace("<enterprise>", enterprise);

  warning(
    HOSTNAME_ENVIRONMENT_MAP[hostnameEnvironment],
    `${hostnameEnvironment} is not a valid environment. Please check your Hostname. Falling back to 'development'`
  );

  warning(
    enterprise,
    `{enterprise} is not a valid enterprise. Please check your Hostname. Falling back to 'development'`
  );

  return { API_HOST, APP_HOST, enterprise, environment };
}

export const { API_HOST, APP_HOST, enterprise, environment } = getEnvironmentSettings();
