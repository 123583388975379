import {
  Loader
} from "./chunk-F5TBRKWI.mjs";
import {
  Icon
} from "./chunk-2VLF44KF.mjs";
import {
  Colors,
  Themes,
  ThemesToColors,
  bg100Hover,
  bg200,
  bg200Hover,
  bg300Hover,
  bg500,
  bg600Hover,
  border500,
  ring500Focus,
  text500,
  text700,
  text700Hover,
  text900Hover
} from "./chunk-Q4E7CXXM.mjs";

// src/components/Button/IconButton.tsx
import {
  forwardRef,
  useMemo
} from "react";
import { useButton } from "@react-aria/button";
import { FocusRing } from "@react-aria/focus";
import { useObjectRef } from "@react-aria/utils";
import clsx from "clsx";

// src/types/Sizes.ts
var ButtonSizes = /* @__PURE__ */ ((ButtonSizes2) => {
  ButtonSizes2["MEDIUM"] = "MEDIUM";
  ButtonSizes2["SMALL"] = "SMALL";
  ButtonSizes2["INLINE"] = "INLINE";
  return ButtonSizes2;
})(ButtonSizes || {});

// src/components/Button/IconButton.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SelectStates = /* @__PURE__ */ ((SelectStates2) => {
  SelectStates2["NONE"] = "NONE";
  SelectStates2["HALF"] = "HALF";
  SelectStates2["SELECTED"] = "SELECTED";
  return SelectStates2;
})(SelectStates || {});
var BgShades = /* @__PURE__ */ ((BgShades2) => {
  BgShades2["WHITE"] = "WHITE";
  BgShades2["SHADE_50"] = "SHADE_50";
  BgShades2["SHADE_100"] = "SHADE_100";
  return BgShades2;
})(BgShades || {});
var colorMapping = (color) => {
  return {
    ["WHITE" /* WHITE */]: {
      ["NONE" /* NONE */]: {
        default: text500[color],
        active: `${bg100Hover[color]} ${text700Hover[color]} ${ring500Focus[color]}`
      },
      ["HALF" /* HALF */]: {
        default: `${text500[color]} ${border500[color]}`,
        active: `${bg200Hover[color]} ${ring500Focus[color]}`
      },
      ["SELECTED" /* SELECTED */]: {
        default: `text-white ${bg500[color]}`,
        active: `${bg600Hover[color]} ${ring500Focus[color]}`
      }
    },
    ["SHADE_50" /* SHADE_50 */]: {
      ["NONE" /* NONE */]: {
        default: text500[color],
        active: `${bg200Hover[color]} ${text700Hover[color]} ${ring500Focus[color]}`
      },
      ["HALF" /* HALF */]: {
        default: `${text700[color]} ${bg200[color]}`,
        active: `${bg300Hover[color]} ${text900Hover[color]} ${ring500Focus[color]}`
      },
      ["SELECTED" /* SELECTED */]: {
        default: `text-white ${bg500[color]}`,
        active: `${bg600Hover[color]} ${ring500Focus[color]}`
      }
    },
    ["SHADE_100" /* SHADE_100 */]: {
      ["NONE" /* NONE */]: {
        default: `${text700[color]}`,
        active: `${bg300Hover[color]} ${text900Hover[color]} ${ring500Focus[color]}`
      },
      ["HALF" /* HALF */]: {
        default: `${text700[color]} ${bg200[color]}`,
        active: `${bg300Hover[color]} ${text900Hover[color]} ${ring500Focus[color]}`
      },
      ["SELECTED" /* SELECTED */]: {
        default: `text-white ${bg500[color]}`,
        active: `${bg600Hover[color]} ${ring500Focus[color]}`
      }
    }
  };
};
var BaseIconButton = forwardRef(
  ({
    color = "GRAY" /* GRAY */,
    icon,
    isDisabled = false,
    isLoading = false,
    onBg = "WHITE" /* WHITE */,
    onClick: onPress,
    selectState = "NONE" /* NONE */,
    size = "MEDIUM" /* MEDIUM */,
    ...props
  }, outerRef) => {
    const ref = useObjectRef(outerRef);
    const isHeadlessUi = props.id?.includes("headless");
    let { buttonProps } = useButton(
      {
        ...props,
        isDisabled: isDisabled || isLoading,
        onPress: isHeadlessUi ? void 0 : onPress,
        //@ts-ignore
        onClick: isHeadlessUi ? onPress : void 0
      },
      ref
    );
    const colorMapped = useMemo(() => colorMapping(color), [color]);
    const classNames = clsx(
      "inline-flex items-center border border-transparent rounded justify-center disabled:opacity-40 disabled:cursor-default focus:outline-none",
      SIZE_MAP[size],
      isDisabled && "opacity-40 cursor-default",
      isLoading && "text-opacity-0 cursor-default",
      colorMapped[onBg][selectState].default,
      !isLoading && !isDisabled && onPress && colorMapped[onBg][selectState].active,
      props.className
    );
    if (onPress) {
      return /* @__PURE__ */ jsx(
        FocusRing,
        {
          focusRingClass: clsx(
            !isLoading && !isDisabled && `focus:ring-4 ${colorMapped[onBg][selectState].active}`
          ),
          children: /* @__PURE__ */ jsx("button", { ...buttonProps, className: classNames, ref, children: /* @__PURE__ */ jsxs("div", { className: "min-h-6 min-w-6 flex items-center justify-center", children: [
            isLoading && /* @__PURE__ */ jsx("div", { className: "absolute", children: /* @__PURE__ */ jsx(Loader, { size: Loader.size.SMALL }) }),
            /* @__PURE__ */ jsx(Icon, { className: SIZE_MAP_ICON[size], icon })
          ] }) })
        }
      );
    }
    return /* @__PURE__ */ jsx("div", { className: classNames, ref, children: /* @__PURE__ */ jsx("div", { className: "min-h-6 min-w-6 flex items-center justify-center", children: /* @__PURE__ */ jsx(Icon, { className: SIZE_MAP_ICON[size], icon }) }) });
  }
);
var IconButton = forwardRef(
  (props, ref) => {
    const { theme } = props;
    const color = theme && ThemesToColors[theme];
    return /* @__PURE__ */ jsx(BaseIconButton, { ...props, color, ref });
  }
);
IconButton.theme = Themes;
IconButton.size = ButtonSizes;
IconButton.onBg = BgShades;
function CloseButton(props) {
  return /* @__PURE__ */ jsx(IconButton, { "aria-label": "Close", ...props, icon: "XMarkIcon" });
}
CloseButton.theme = Themes;
CloseButton.size = ButtonSizes;
CloseButton.onBg = BgShades;
function IconIndicator({ ...props }) {
  return /* @__PURE__ */ jsx(BaseIconButton, { ...props });
}
IconIndicator.color = Colors;
IconIndicator.size = ButtonSizes;
IconIndicator.onBg = BgShades;
IconIndicator.selectState = SelectStates;
var SIZE_MAP_ICON = {
  ["MEDIUM" /* MEDIUM */]: "w-5 h-5",
  ["SMALL" /* SMALL */]: "w-4 h-4",
  ["INLINE" /* INLINE */]: "w-3 h-3"
};
var SIZE_MAP = {
  ["MEDIUM" /* MEDIUM */]: "px-2 py-2 text-base",
  ["SMALL" /* SMALL */]: "px-[0.15rem] py-1.5 text-sm",
  ["INLINE" /* INLINE */]: "px-0 mx-0 py-1 text-xs"
};

export {
  ButtonSizes,
  BgShades,
  IconButton,
  CloseButton,
  IconIndicator,
  SIZE_MAP_ICON
};
