import DOMPurify from "dompurify";
import { marked } from "marked";
import { mangle } from "marked-mangle";

import styles from "./Instructions.module.scss";

marked.use(mangle());

interface Props {
  instructions?: string | null;
}

export function Instructions(props: Props) {
  const { instructions } = props;

  if (instructions == null) {
    return null;
  }

  return (
    <div
      className={styles.Instructions}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(marked.parse(instructions, { headerIds: false })),
      }}
    />
  );
}
