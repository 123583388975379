import React from "react";
import { API_HOST, enterprise } from "@app/utils/enterprise";
import clsx from "clsx";

import { Appointment_appointment_participants as Participant } from "../../types/api";
import { Icon } from "../Icon/Icon";

import styles from "./Avatar.module.scss";

function getInitials(firstName: string | null, lastName: string | null) {
  return [firstName, lastName]
    .filter(Boolean)
    .map((part) => part?.charAt(0) ?? "")
    .join("");
}

interface Props {
  participant: Pick<Participant, "avatar" | "email" | "firstName" | "lastName">;
  size?: "small" | "normal" | "large";
}

// TODO: Adjust me when Core API becomes multi-tenant
const PICTURES_HOST = `https://${API_HOST.replace(
  "api",
  enterprise
)}/api/enterprises/${enterprise}`;

export function Avatar({ participant, size = "normal" }: Props) {
  return (
    <div className={clsx(styles.Avatar, styles[size])}>
      {participant.email != null && size === "large" && (
        <a className={styles.badge} href={`mailto:${participant.email}`} title={participant.email}>
          <Icon icon="envelope" size="xs" />
        </a>
      )}
      <div className={styles.inner}>
        <div className={styles.initials}>
          {getInitials(participant.firstName, participant.lastName)}
        </div>
        {participant.avatar != null && (
          <div
            className={styles.background}
            style={{ backgroundImage: `url(${PICTURES_HOST}${participant.avatar})` }}
          />
        )}
      </div>
    </div>
  );
}
